import { Toast } from 'antd-mobile'

// var wx = require('weixin-js-sdk');

function jsSDK(params, cb) {
    console.log('jssdk params', params)
    if (typeof window.WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
            console.log('document.addEventListener')
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady(params, cb), false)
        } else if (document.attachEvent) {
            console.log('document.attachEvent')
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady(params, cb))
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady(params, cb))
        }
        return
    }
    onBridgeReady(params, cb)
}
function onBridgeReady(params, cb) {
    window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
            debug: true,
            appId: params.appId, // 公众号名称，由商户传入
            timeStamp: params.timeStamp, // 时间戳，自1970年以来的秒数
            nonceStr: params.nonceStr, // 随机串
            package: params.orderNumber,
            signType: params.signType, // 微信签名方式：
            paySign: params.paySign, // 微信签名
        },
        function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
                cb()
                // otherData.jump(otherData.path, { state: jumpData })
                // Toast.show({
                //     afterClose: () => {},
                // })
            } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
                alert('支付失败，错误详情请看console')
            } else {
                // otherData.jump(otherData.path,{replace: true})
            }
        },
    )
}

export default jsSDK
