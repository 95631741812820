import CryptoJS from 'crypto-js'
import JSEncrypt from 'jsencrypt'
function getEncr() {
  let arr1 = [96, [54, [121, [99, [119, [55, [53, [97]]]]]]]]
  let arr2 = [106, [62, [60, [110, [103, [124, [107, 32]]]]]]]
  let arr = [...arr1.flat(Infinity), ...arr2.flat(Infinity)]
  let str = ''
  arr.forEach((d, i) => {
    const n = d ^ i+1
    str += String.fromCharCode(n)
  })
  return str
}
function getDecr() {
  let arr1 = [106, [119, [122, [125, [51, [115, [117]]]]]]]
        let arr2 = [107, [113, [122, [99, [56, [56, [119, [99, 101]]]]]]]]
  let arr = [...arr1.flat(Infinity), ...arr2.flat(Infinity)]
  let str = ''
  arr.forEach((d, i) => {
    const n = d ^ i+1
    str += String.fromCharCode(n)
  })
  return str
}
export function AesEncrypt(word) {
  const key = CryptoJS.enc.Utf8.parse(getEncr()) || CryptoJS.enc.Utf8.parse(sessionStorage.getItem('publicKey'))
  let encrypted = ''
  if (typeof (word) === 'object') { // 对象格式的转成json字符串
    const data = JSON.stringify(word)
    const srcs = CryptoJS.enc.Utf8.parse(data)
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
  } else {
    let srcs
    if (word) {
      srcs = CryptoJS.enc.Utf8.parse(String(word))
    } else {
      srcs = '' // 如果没有 就是空字符串
    }
    encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    })
  }
  return encrypted.toString()
}

export function Decrypt(data) {
  const word = data
  const key = CryptoJS.enc.Utf8.parse(getDecr()) || CryptoJS.enc.Utf8.parse(sessionStorage.getItem('publicKey'))// Latin1 w8m31+Yy/Nw6thPsMpO5fg==
  const decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 })
  return CryptoJS.enc.Utf8.stringify(decrypt).toString()
}
const araEncr = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC+ZWNVXctfokltDDBrJ4d9TJJ938UrxIPVZwFyYJdAwGwWyiXQgOEy/IIhBgeveN2Le7pPRsanZcw7DwecxzZw9oDscm9iAdTSa8/Xe1KFWiuZFb/P6e688koH0bUqm/JyoWV1X6dNLolLJ0COtkX5u/mgvI/+U0i3EUFIGygAFwIDAQAB'
export function RsaEncrypt(data) {
  let publicKey = `-----BEGIN PUBLIC KEY----- ${araEncr} -----END PUBLIC KEY-----`
  // 加密
  if (typeof (data) === 'object') { // 对象格式的转成json字符串
    data = JSON.stringify(data)
  }
  const Encrypt = new JSEncrypt()
  Encrypt.setPublicKey(publicKey)
  return Encrypt.encrypt(data)
}
