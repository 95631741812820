import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import 'lib-flexible'
import '../src/services'
//引入所有src/icons 下的svg格式的icon
import './icons/index'
import './icons/iconfont'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    // <React.StrictMode>
    //     <App />
    // </React.StrictMode>
    <App />
)
