/**
 * 个人中心
 */

import axios from '../utils/http'

const registerApi = {
    /* ----------------------------------------------------挂号-------------------------------------------------------- */

    // 挂号配置
    queryRegisterConfig(data) {
        return axios.get(`/register/api/queryRegisterConfig`, data)
    },
    // 科室列表
    queryDepts(data) {
        return axios.get(`/register/api/queryDepts`, data)
    },
    // 查询显示排班时间头
    getRegTimes(data) {
        return axios.get(`/register/api/getRegTimes`, data)
    },
    // 查询时间某天是否有排班
    showDate(data) {
        return axios.get(`/register/api/showDate`, data)
    },
    // 按科日期排班
    doctorSchedulingByDept(data) {
        return axios.get(`/register/api/doctorSchedulingByDept`, data)
    },
    // 按科医生排班
    doctorSchedulingByDate(data) {
        return axios.get(`/register/api/doctorSchedulingByDate`, data)
    },
    // 根据医生HisId查询医生信息
    queryDoctorInfo(data) {
        return axios.get(`/register/api/queryDoctorInfo`, data)
    },
    // 医生分时段号源
    queryDoctorRegisterSource(data) {
        return axios.get(`/register/api/queryDoctorRegisterSource`, data)
    },
    // 须知内容
    querySystemNotice(data) {
        return axios.get(`/register/api/querySystemNotice`, { data })
    },
    // 关注或取消
    addUserCollectionDoctor(data) {
        return axios.post(`/register/api/addUserCollectionDoctor`, data)
    },
    // 根据科室hisId查询科室信息
    queryDeptInfo(data) {
        return axios.get(`/register/api/queryDeptInfo`, data)
    },
    // 验证科室挂号
    verificationRegister(data) {
        return axios.get(`/register/api/verificationRegister`, data)
    },
    // 挂号下单
    orderRegistration(data) {
        return axios.post(`/register/api/orderRegistration`, data)
    },
    // 下单支付
    placeOrder(data) {
        return axios.post(`/register/api/placeOrder`, data)
    },
    // 挂号记录-列表
    getRegisterList(data) {
        return axios.get(`/user/record/register/getRegisterList`, data)
    },
    // 挂号记录-编辑(退号-取消)
    editRegisterInfo(data) {
        return axios.post(`/user/record/register/editRegisterInfo`, data)
    },
    // 挂号记录-详情
    getRegisterInfo(data) {
        return axios.get(`/user/record/register/getRegisterInfo`, data)
    },
}

export default registerApi
