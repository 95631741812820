/**
* axios封装
* 请求拦截、响应拦截、错误统一处理
*/
import axios from 'axios'
import myToast from '@/utils/Toast'
import { getStorage, removeStorage } from '@/utils/storage'
import { getQuery, loginApi } from '@/utils'
import { AesEncrypt, Decrypt, RsaEncrypt } from './encrypt'
import ReactDOM from 'react-dom/client'
import Loading from '../views/home/loading'

let count = 0
const showLoading = () => {
  if(count === 0) {
    let dom = document.createElement('div')
    dom.setAttribute('id','loading-box')
    document.body.appendChild(dom)
    ReactDOM.createRoot(dom).render(<Loading/>)
  }
  count++
}
const hideLoading = () => {
  if(count <= 0) return
  count--
  if(count === 0) {
    document.body.removeChild(document.getElementById('loading-box'))
  }
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, other) => {
  // 状态码判断
  switch (status) {
    case 401:
      loginApi(() => window.location.reload())
      break;
    case 402:
    case 403:
      window.location.href = window.location.origin + '/nwx/loading' + getQuery()
      myToast.fail('连接服务器失败---' + status + '---' + other, 2, () => { })
      break;
    default:
      myToast.fail('连接服务器失败---' + status + '---' + other, 2, () => {
        // window.location.href = window.location.origin + '/netError'
      })
      break;
  }
}

// 创建axios实例
const instance = axios.create({
  baseURL: process.env.APP_URL,
  timeout: 1000 * 100,
})

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
  config => {
    !config.loading && showLoading()
    config.headers['Content-Type'] = 'application/json'
    if (getStorage('accessToken')) {
      config.headers['accessToken'] = getStorage('accessToken') // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // switch (config.reqType) {
    //   case 'local': // 测试正式环境的api
    //     config.baseURL = 'http://192.168.100.111:48121'; break // 调用第三方的api
    //   default:
    //     config.baseURL = process.env.APP_URL
    // }

    console.log(`%c -------------${config.url}请求参数-------------：${JSON.stringify(config.data)}`,`background-color:#006fff;color:#fff;padding:2px 5px;`);
    console.log(config, 'configconfigconfig');
    if (config.is_login) {
      config.data = RsaEncrypt(config.data)
    } else {
      if (!config.noAes) {
        if (config.method === 'get') {
          // noParam 为无参的时候 不需要query
          config.url = config.noParam
            ? config.url
            : config.url + '?query=' + (config.data ? encodeURIComponent(AesEncrypt(config.data)) : config.data)
        } else {
          config.data = config.data ? AesEncrypt(config.data) : config.data
        }
      } else {
        // 不加密
        if (config.data === '' || config.data === null) {
          config.data = 'null'
        }
      }
    }

    return config
  },
  error => {
    Promise.error(error)
  })

/**
 * 响应拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.response.use(
  // 请求成功
  res => {
    hideLoading()
    if (res.status !== 200) {
      console.log('状态码为0了！！！！！！！')
      return
    }
    const data = res.data
    console.log(data,'data111111111111111');
    if (data.status === 200) {
      if (data.data) {
        try {
          // console.log(`%c -------------${res.config.url.slice(0,res.config.url.indexOf('?')).replaceAll(res.config.baseURL,'')}返回参数-------------：`,`background-color:#07C160;color:#fff;padding:2px 5px;`, JSON.parse(Decrypt(data.data)))
          data.data = data.data ? JSON.parse(Decrypt(data.data)) : Decrypt(data.data)
        } catch (error) {
          console.log(error);
        }
      }
      // let url = res.config.url
      // if (url.includes('query')) {
      //     url = url.slice(0,url.indexOf('?query'))
      // }
      return Promise.resolve(data)
    } else {
      myToast.fail(data.message,1)
      return Promise.reject(res)
    }
  },
  // 请求失败
  error => {
    hideLoading()
    const { response } = error
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.message)
      return Promise.reject(response.data)
    } else {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      // window.location.href = window.location.origin + '/netError'
      return Promise.reject(false)
    }
  })

export default instance
