import { Toast } from 'antd-mobile'
import { UploadOutline } from 'antd-mobile-icons'

/**
 * duration: 单位 秒
 */
const myToast = {
  success: function(content='成功！', duration=2, cb=()=>{}) {
    Toast.show({
      icon: 'success',
      content,
      duration: 1000 * duration,
      afterClose: () => {
        cb && cb()
      } 
    })
  },
  fail: function(content='失败！', duration=2, cb=()=>{}) {
    Toast.show({
      icon: 'fail',
      content,
      duration: 1000 * duration,
      afterClose: () => {
        cb && cb()
      } 
    })
  },
  loading: function(content='加载中…', duration=0) {
    Toast.show({
      icon: 'loading',
      content,
      duration: 1000 * duration,
    })
  },
  clear: function() {
    Toast.clear()
  }
}

export default myToast