import axios from '@/utils/http' // 导入http中创建的axios实例

const outpatientApi = {
  // -------------------------门诊缴费-------------------------------------------------------
  queryOutpatientConfig(params) { //门诊缴费配置管理
    return axios.get(`/query/operate/function/outpatient/queryOutpatientConfig`, )
  },
  noteQuery(params) { //就诊须知-内容须知条件查询
    return axios.get(`/query/operate/portal/note/query`,{data: params} )
  },
  getBillInfo(params) { //待缴费列表
    return axios.get(`/pay/api/getBillInfo`, { data: params }, )
  },
  getInfo(params) { //门诊支付（待缴费，按门诊号时用）
    return axios.post(`/pay/api/getInfo`, params, )
  },
  payOrderOutpatient(params) { //门诊支付
    return axios.post(`/pay/api/payOrderOutpatient`, params)
  },
  
  // -------------------------报告查询-------------------------------------------------------
  getExamineConfig(params) { //配置
    return axios.get(`/query/report/getConfig`, { data: params }, )
  },
  getCase(params) { // 门诊报告记录
    return axios.get(`/query/comprehensive/getCase`, { data: params }, )
  },
  getHospitalizationList(params) { // 住院报告记录
    return axios.get(`/query/comprehensive/getHospitalizationList`, { data: params }, )
  },
  healthRecordList(params) { // 体检报告记录
    return axios.get(`/query/report/healthRecordList`, { data: params }, )
  },
  healthReportFile(params) { // 体检报告(pdf)记录
    return axios.get(`/query/report/healthReportFile`, { data: params }, )
  },
  inspectExamineList(params) { // 检验检查报告列表
    return axios.get(`/query/report/inspectExamineList`, { data: params }, )
  },
  cellReportList(admNo) { // 患者细胞学报告列表
    return axios.get(`/query/report/cellReportList`, { data:admNo }, )
  },
  inspectExamineInfo(params) { // 检查、检验报告详情
    return axios.get(`/query/report/inspectExamineInfo`, { data:params }, )
  },
  cellReportInfo(params) { // 细胞学报告详情
    return axios.get(`/query/report/cellReportInfo`, { data:params }, )
  },
  healthReportInfo(params) { // 体检报告详情
    return axios.get(`/query/report/healthReportInfo`, { data:params }, )
  },


  // getExamineList(params) { //报告list
  //   return axios.get(`/query/report/getExamineList`, { data: params }, )
  // },
  // getExamineDetail(params) { //详情
  //   return axios.get(`/query/report/getExamineDetail`, { data: params }, )
  // },
  // -------------------------体检缴费-------------------------------------------------------
  getHealthBillInfo(data) { // 代缴费列表查询
    return axios.get(`/pay/health/getHealthBillInfo`, { data }, )
  },
  getHealthBillRecord(data) { // 体检缴费记录
    return axios.get(`/pay/health/getHealthBillRecord`, {data}, )
  },
  queryHealthConfig(data) { // 查询体检缴费配置
    return axios.get(`/pay/health/queryHealthConfig`, { data }, )
  },
  payOrderHealth(data) { // 体检缴费支付
    return axios.post(`/pay/health/payOrderHealth`, data, )
  },

  // -------------------------智能导诊-------------------------------------------------------
  listBodys(data) { // 查询身体部位列表
    return axios.get(`/query/guide/listBodys`, { data }, )
  },
  getCategory(data) { // 查询症状类别列表
    return axios.get(`/query/guide/getCategory`, { data }, )
  },
  getCategoryAndDepartmentsX(data) { // 查询症状类别和对应科室列表（新）
    return axios.get(`/query/guide/getCategoryAndDepartmentsX`, { data }, )
  },
  // -------------------------入院登记-------------------------------------------------------
  getRegistrationList(data) { // 入院证信息列表
    return axios.get(`/hospitalization/api/getRegistrationList`, { data }, )
  },
  addRegistration(data) { // 入院证信息登记
    return axios.post(`/hospitalization/api/addRegistration`, data, )
  },
  // -------------------------新冠预防中药-------------------------------------------------------
  // 获取新冠预防中药配置
  getConvenienceDrugConfig(data) {
    return axios.get(`/register/prevention/getConfig`, { reqType: 'local' })
  },
  // 获取处方
  getPrescription(data) {
    return axios.get(`/register/prevention/getPrescription`, { reqType: 'local' })
  },
  // 创建新冠预防中药订单
  createOrder(data) {
    return axios.post(`/register/prevention/createOrder`, data, { reqType: 'local' })
  },
  // 查询申请记录
  getRecordList(data) {
    return axios.get(`/register/prevention/list`, { reqType: 'local', data })
  },
  // 查询申请记录详情
  getRecord(data) {
    return axios.get(`register/prevention/getRecord`, { reqType: 'local', data })
  },
  // 支付下单
  placeOrder(data) {
    return axios.post(`/register/prevention/placeOrder`, data, { reqType: 'local' })
  },
}


export default outpatientApi