import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dialog } from 'antd-mobile'
import CryptoJS from 'crypto-js'
import moment from 'moment'
import { setStorage } from '@/utils/storage'
import axios from '@/utils/http' // 导入http中创建的axios实例
import homeApi from '@/api/home'
import uncleicApi from '@/api/uncleic'
import registerApi from '@/api/register'



// 动态导入路由组件
export const loadLazy = (path) => {
    return React.lazy(() => import(`@/views${path}`))
}

// base64加密/解密
export const encrypt_base64 = (rawStr = '') => {
    console.log('rawStr', rawStr)
    rawStr = JSON.stringify(rawStr)
    const wordArray = CryptoJS.enc.Utf8.parse(rawStr)
    const base64 = CryptoJS.enc.Base64.stringify(wordArray)
    // console.log('encrypted:', base64)
    // console.log('if decrypt_base64:', this.decrypt_base64(base64))
    return base64
}
export const decrypt_base64 = (encryptStr = '') => {
    // decrypt
    console.log('encryptStr', encryptStr)
    const parsedWordArray = CryptoJS.enc.Base64.parse(encryptStr)
    let parsedStr = CryptoJS.enc.Utf8.stringify(parsedWordArray).toString()
    try {
        parsedStr = JSON.parse(parsedStr)
    } catch (e) {
        console.log('e', e)
    }
    console.log('parsed:', parsedStr)
    return parsedStr
}

export const HookPush = () => {
    function widthHook(WrapCompontent) {
        // 设置别名
        WrapCompontent.displayName = `WithHook${getDisplayName(WrapCompontent)}`
        return function QiLincompont() {
            const navigate = useNavigate()
            return <WrapCompontent to={navigate}></WrapCompontent>
        }
    }

    // 设置别名
    function getDisplayName(WrapCompontent) {
        return WrapCompontent.displayname || WrapCompontent.name || 'Component'
    }
}
// dialog 对话框
/**
 *
 * @param {*} title 标题
 * @param {*} content 内容
 * @param {*} callback 回调函数
 *
 */
export const CommonDialog = ({ title = '提示', visible = true, content = '是否取消关注', callback }) => {
    Dialog.confirm({
        // image: collectionIcon2,
        title,
        content,
        confirmText: '确认',
        cancelText: '取消',
        visible,
        onConfirm: () => {
            callback && callback()
        },
    })
}

// 指定容器滚动到最底部
export const scrollToBottom = (className) => {
    setTimeout(scroll, 100)
    function scroll() {
        const domWrapper = document.querySelector(className) // 外层容器 出现滚动条的dom
        ;(function smoothscroll() {
            const currentScroll = domWrapper.scrollTop // 已经被卷掉的高度
            const clientHeight = domWrapper.offsetHeight // 容器高度
            const scrollHeight = domWrapper.scrollHeight // 内容总高度
            if (scrollHeight - 10 > currentScroll + clientHeight) {
                window.requestAnimationFrame(smoothscroll)
                domWrapper.scrollTo(0, currentScroll + (scrollHeight - currentScroll - clientHeight) / 2)
            }
        })()
    }
}

// 是否是微信环境
export const wx_browser = () => {
    let ua = navigator.userAgent.toLowerCase()
    let isWeixin = ua.indexOf('micromessenger') != -1
    if (isWeixin) {
        return true
    } else {
        return false
    }
}

// 获取查询参数
export const getQuery = () => {
    let query = window.location.search
    let name = window.location.pathname
    name = name.replace('/nwx', '')
    if (query) {
        query = query + '&pathname=' + name
    } else {
        query = '?pathname=' + name
    }
    return query
}
// 登录操作
export const loginApi = (cb) => {
    homeApi
        .login({ username: 'admin', password: 'NMCADMIN3165078..' })
        // .login({ username: 'liujianeng', password: 'Aa_123456789' })
        .then((r) => {
            setStorage('accessToken', r.token)
            cb && cb(r)
        })
        .catch((err) => {
            console.log('登录失败', err)
        })
}
// 生成26个大写字母
export const generateBig_1 = () => {
    var str = []
    for (var i = 65; i < 91; i++) {
        str.push(String.fromCharCode(i))
    }
    return str
}

// 获取就诊须知（ 通过code获取）
export const getPageTips = (code) => {
    return new Promise(async (resolve, reject) => {
        const res = await registerApi.querySystemNotice(code)
        if (!res.data) return reject()
        return resolve(res.data.content)
    })
}
// 获取就诊须知（ 通过code获取）
export const getPageTips2 = (code) => {
    return new Promise(async (resolve, reject) => {
        const res = await uncleicApi.getSystemNotice(code)
        if (!res.data) return reject()
        return resolve(res.data.content)
    })
}

/**
 * 获取功能模块配置信息
 * @param {*} path 接口地址
 * @param {*} key 缓存本地的key名
 * @param {*} callback 回调函数
 */
export const getPageConfig = async (path, key='config', callback) => {
    const res = await axios.get(path)
    if(res.data) {
        setStorage(key, res.data)
        callback(res.data)
    }
}
/**
 * 获取须知内容
 * @param {*} code
 * @param {*} callback 回调函数
 */
export const getQueryContent = async (code, callback) => {
    const res = await registerApi.querySystemNotice(code)
    callback(res.data)
}
/**
 * 后端返回表单支付
 * @param {*} resData  返回给你的form,获取到的表单内容
 */
export const FormPay = (resData) => {
    const div = document.createElement('div') //后端返回的数据类似一个form表单，创建一个div后执行表单的submit就OK了
    // div.id = 'alipay'
    div.innerHTML = resData
    document.body.appendChild(div)
    console.log(document.forms[0])
    document.forms[0].submit()
}

// 手机号脱敏处理
export const noPassByMobile = (str) => {
    //中间四位脱敏
    if (null != str && str != undefined) {
        var pat = /(\d{3})\d*(\d{4})/
        return str.replace(pat, '$1****$2')
    } else {
        return ''
    }
}
// 姓名脱敏处理
export const noPassByName = (str) => {
    //3个字以内脱敏第一个字，4—6个字脱敏前两个字，大于6个字脱敏第3—6个字
    if (null != str && str != undefined) {
        if (str.length <= 3) {
            return '*' + str.substring(1, str.length)
        } else if (str.length > 3 && str.length <= 6) {
            return '**' + str.substring(2, str.length)
        } else if (str.length > 6) {
            return str.substring(0, 2) + '****' + str.substring(6, str.length)
        }
    } else {
        return ''
    }
}
// 身份证号脱敏处理
export const noPassById = (str) => {
    if (null != str && str != undefined) {
        var pat = /(\d{4})\d*(\d{4})/
        return str.replace(pat, '$1***********$2')
    } else {
        return ''
    }
}
// 邮箱脱敏处理
export const noPassByEmail = (email) => {
    let new_email = email
    if (String(email).indexOf('@') > 0) {
        let str = email.split('@')
        let _s = ''
        if (str[0].length > 3) {
            //@前面多于3位
            for (let i = 3; i < str[0].length; i++) {
                _s += '*'
            }
            new_email = str[0].substr(0, 3) + _s + '@' + str[1]
        } else {
            //@前面小于等于于3位
            for (let i = 1; i < str[0].length; i++) {
                _s += '*'
            }
            new_email = str[0].substr(0, 1) + _s + '@' + str[1]
        }
    }
    return new_email
}

/**
 * 获取近几天的日期范围(默认30天)
 * @param {*} num 近几天
 * @return [YYYY-MM-DD, YYYY-MM-DD]
 */
export const getNearbyTime = (num=30) => {
    let startTime = moment().subtract(num, "days").format("YYYY-MM-DD")
    let endTime = moment().format('YYYY-MM-DD')
    return [startTime, endTime]
}

/**
 * 埋点统计
 * functionId: 功能id
 * functionName: 功能url
 * isInit : 是否初始进微信首页 默认false
 */
export const functionCount = async (functionId, functionName, isInit=false) => {
    let params = {functionId, functionName}
    if(isInit) {
        params = {
            functionId: 0,
            functionName: '微信访问次数'
        }
    }
    try {
        const res = await homeApi.functionCount(params)
    } catch (error) {
        console.log('埋点失败...', params);
    }
}

// 身份证号验证
export const validateIdent = {
    aIdentityCode_City: { // 城市代码列表
        11: "北京", 12: "天津", 13: "河北", 14: "山西", 15: "内蒙古", 21: "辽宁", 22: "吉林",
        23: "黑龙江 ", 31: "上海", 32: "江苏", 33: "浙江", 34: "安徽", 35: "福建", 36: "江西",
        37: "山东", 41: "河南", 42: "湖北 ", 43: "湖南", 44: "广东", 45: "广西", 46: "海南",
        50: "重庆", 51: "四川", 52: "贵州", 53: "云南", 54: "西藏 ", 61: "陕西", 62: "甘肃",
        63: "青海", 64: "宁夏", 65: "新疆", 71: "台湾", 81: "香港", 82: "澳门", 91: "国外 "
    },
    IdentityCode_isCardNo(card) {//检查号码是否符合规范，包括长度，类型
        var reg = /(^\d{15}$)|(^\d{17}(\d|X)$)/; //身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
        if (reg.test(card) === false) {
            return false;
        }
        return true;
    },
    IdentityCode_checkProvince(card) { //取身份证前两位，校验省份
        var province = card.substr(0, 2);
        if (validateIdent.aIdentityCode_City[province] == undefined) {
            return false;
        }
        return true;
    },
    IdentityCode_checkBirthday(card) { //检查生日是否正确，15位以'19'年份来进行补齐。
        var len = card.length;
        //身份证15位时，次序为省（3位）市（3位）年（2位）月（2位）日（2位）校验位（3位），皆为数字
        if (len == '15') {
            var re_fifteen = /^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/;
            var arr_data = card.match(re_fifteen); // 正则取号码内所含出年月日数据
            var year = arr_data[2];
            var month = arr_data[3];
            var day = arr_data[4];
            var birthday = new Date('19' + year + '/' + month + '/' + day);
            return validateIdent.IdentityCode_verifyBirthday('19' + year, month, day, birthday);
        }
        //身份证18位时，次序为省（3位）市（3位）年（4位）月（2位）日（2位）校验位（4位），校验位末尾可能为X
        if (len == '18') {
            var re_eighteen = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/;
            var arr_data = card.match(re_eighteen); // 正则取号码内所含出年月日数据
            var year = arr_data[2];
            var month = arr_data[3];
            var day = arr_data[4];
            var birthday = new Date(year + '/' + month + '/' + day);
            return validateIdent.IdentityCode_verifyBirthday(year, month, day, birthday);
        }
        return false;
    },
    IdentityCode_verifyBirthday(year, month, day, birthday) {//校验日期 ，15位以'19'年份来进行补齐。
        var now = new Date();
        var now_year = now.getFullYear();
        //年月日是否合理
        if (birthday.getFullYear() == year
            && (birthday.getMonth() + 1) == month
            && birthday.getDate() == day) {
            return true;
        }
        return false;
    },
    IdentityCode_checkParity(card) { //校验位的检测
        card = validateIdent.IdentityCode_changeFivteenToEighteen(card); // 15位转18位
        var len = card.length;
        if (len == '18') {
            var arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
            var arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
            var cardTemp = 0, i, valnum;
            for (i = 0; i < 17; i++) {
                cardTemp += card.substr(i, 1) * arrInt[i];
            }
            valnum = arrCh[cardTemp % 11];
            if (valnum == card.substr(17, 1)) {
                return true;
            }
            return false;
        }
        return false;
    },
    IdentityCode_changeFivteenToEighteen(card) {  //15位转18位身份证号
        if (card.length == '15') {
            var arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
            var arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
            var cardTemp = 0, i;
            card = card.substr(0, 6) + '19' + card.substr(6, card.length - 6);
            for (i = 0; i < 17; i++) {
                cardTemp += card.substr(i, 1) * arrInt[i];
            }
            card += arrCh[cardTemp % 11];
            return card;
        }
        return card;
    },
    IdentityCodeValid(card) {//   身份证号码检验主入口
        let pass = true;
        let sex = ''
        //是否为空
        if (pass && card === '')
            pass = false;
        //校验长度，类型
        if (pass && validateIdent.IdentityCode_isCardNo(card) === false)
            pass = false;
        //检查省份
        if (pass && validateIdent.IdentityCode_checkProvince(card) === false)
            pass = false;
        //校验生日
        if (pass && validateIdent.IdentityCode_checkBirthday(card) === false)
            pass = false;
        //检验位的检测
        if (pass && validateIdent.IdentityCode_checkParity(card) === false)
            pass = false;
        if (pass) {
            var iCard = validateIdent.IdentityCode_changeFivteenToEighteen(card);
            if (parseInt(iCard.charAt(16)) % 2 == 0) {
                sex = "0"; // 女生
            } else {
                sex = "1"; // 男生
            }
            return true
        } else {
            return false
        }
    }
}

// 查询参数转对象
export function getSearchObj(searh) {
    if (!searh) return {}

    let obj = {}
    let searhReplace = searh.replace('?', '')
    let searchArr = searhReplace.split('&')
    searchArr.forEach((item) => {
        let itemArr = item.split('=')
        obj[itemArr[0]] = itemArr[1]
    })

    return obj
}

/**
 * h5 复制功能
 * @param {String} content 复制的内容
 * @return 执行浏览器复制命令
 */
export const h5Copy = (content='') => {
    let textarea = document.createElement("textarea")
    textarea.value = content
    textarea.readOnly = "readOnly"
    document.body.appendChild(textarea)
    textarea.select() // 选择对象
    textarea.setSelectionRange(0, content.length) //复制的内容的范围 
    let result = document.execCommand("copy") // 执行浏览器复制命令
    textarea.remove()
    return result
}

/**
 * 通过身份证获取性别,出生日期
 * @param {*} idCard 身份证
 * @returns info {sex:1, birthday:YYYY-MM-DD}
 */
export function getBirthdayAndSex (idCard){
    if(!idCard) return {}

    let birthday = "";
    let sex = '';
    if (idCard.length==18) {
        birthday = idCard.substr(6,8);
        birthday = birthday.replace(/(.{4})(.{2})/,"$1-$2-");
        sex = idCard.charAt(16)%2==0?"0":"1";

    }else if(idCard.length==15){
        birthday = "19"+idCard.substr(6,6);
        birthday = birthday.replace(/(.{4})(.{2})/,"$1-$2-");
        sex = idCard.charAt(14)%2==0?"0":"1";
    }
    return { sex, birthday}
}

/**
 * 校验是否是开发人员
 * @param {*} openid 
 * @param {*} cb 回调结果
 */
 export async function isDeveloper(openid, cb) {
    const [err, res] = await to(homeApi.test(openid))
    if(res.data.flag === 200) {
        return true
    }else {
        return false
    }
  }