import { loadLazy } from '@/utils'

const moreRoutes = [
    {
        path: 'medSend',
        element: loadLazy('/serviceMore/medSend/apply/jump'),
        title: '病历邮寄',
    },
    {
        path: 'applyInfo',
        element: loadLazy('/serviceMore/medSend/applyInfo/jump'),
        title: '病历邮寄',
    },
    {
        path: 'applyComfirm',
        element: loadLazy('/serviceMore/medSend/applyComfirm/jump'),
        title: '病历邮寄',
    },
    {
        path: 'payComplete2',
        element: loadLazy('/serviceMore/medSend/payComplete/jump'),
        title: '病历邮寄',
    },
    {
        path: 'applicationRecord',
        element: loadLazy('/serviceMore/medSend/applicationRecord/jump'),
        title: '申请记录',
    },
    {
        path: 'applicationRecordInfo/:id',
        element: loadLazy('/serviceMore/medSend/applicationRecordInfo/jump'),
        title: '申请记录详情',
    },
    {
        path: 'identityInfo',
        element: loadLazy('/serviceMore/medSend/identityInfo/index'),
        title: '身份详情',
    },
    {
        path: 'medicalRecordInfo',
        element: loadLazy('/serviceMore/medSend/medicalRecordInfo/jump'),
        title: '病案详情',
    },
    {
        path: 'medicalOrderInfo',
        element: loadLazy('/serviceMore/medSend/medicalOrderInfo/jump'),
        title: '订单详情',
    },
    {
        path: 'medicalOrderComfirm',
        element: loadLazy('/serviceMore/medSend/medicalOrderComfirm/jump'),
        title: '订单确认',
    },
    {
        path: 'payComplete/:status/:type',
        element: loadLazy('/serviceMore/medSend/payComplete/jump'),
        title: '成功',
    },

    {
        path: 'inspectionCheckAttention',
        element: loadLazy('/serviceMore/inspectionCheckAttention/index'),
        title: '检验检查注意事项',
    },
    {
        path: 'inspectionCheckInfoConfirm',
        element: loadLazy('/serviceMore/InspectionCheck/confirm/index'),
        title: '检查信息确认',
    },

    {
        path: 'clinicGuide',
        element: loadLazy('/serviceMore/clinicGuide/index'),
        title: '就诊指南',
    },
    {
        path: 'pricePublic',
        element: loadLazy('/serviceMore/pricePublic/index'),
        title: '物价公示',
    },
    {
        path: 'clinicGuideDetails',
        element: loadLazy('/serviceMore/clinicGuideDetails/index'),
        title: '就诊指南',
    },
    {
        path: 'news',
        element: loadLazy('/serviceMore/news/index'),
        title: '新闻动态',
    },
    {
        path: 'newsDetails',
        element: loadLazy('/serviceMore/newsDetails/index'),
        title: '新闻动态',
    },
    {
        path: 'healthEducation',
        element: loadLazy('/serviceMore/healthEducation/index'),
        title: '健康宣教',
    },
    {
        path: 'registrationCollege',
        element: loadLazy('/serviceMore/registrationCollege/index'),
        title: '来院信息登记',
    },
    {
        path: 'healthEducationList',
        element: loadLazy('/serviceMore/healthEducationList/index'),
        title: '医学知识专栏',
    },
    {
        path: 'healthEducationDetails',
        element: loadLazy('/serviceMore/healthEducationDetails/index'),
        title: '健康宣教详情',
    },
    {
        path: 'medSend',
        element: loadLazy('/serviceMore/medSend/apply/index2'),
        title: '病历邮寄',
    },
    {
        path: 'applyInfo',
        element: loadLazy('/serviceMore/medSend/applyInfo/index'),
        title: '病历邮寄',
    },
    {
        path: 'applyComfirm',
        element: loadLazy('/serviceMore/medSend/applyComfirm/index'),
        title: '病历邮寄',
    },
    {
        path: 'applicationRecord',
        element: loadLazy('/serviceMore/medSend/applicationRecord/index'),
        title: '申请记录',
    },
    {
        path: 'applicationRecordInfo',
        element: loadLazy('/serviceMore/medSend/applicationRecordInfo/index'),
        title: '申请记录详情',
    },
    {
        path: 'identityInfo/:id',
        element: loadLazy('/serviceMore/medSend/identityInfo/index'),
        title: '身份详情',
    },
    {
        path: 'medicalRecordInfo/:id',
        element: loadLazy('/serviceMore/medSend/medicalRecordInfo/index'),
        title: '病案详情',
    },
    {
        path: 'medicalOrderInfo/:id',
        element: loadLazy('/serviceMore/medSend/medicalOrderInfo/index'),
        title: '订单详情',
    },
    {
        path: 'medicalOrderComfirm',
        element: loadLazy('/serviceMore/medSend/medicalOrderComfirm/index'),
        title: '订单确认',
    },
    {
        path: 'payComplete/:status/:type',
        element: loadLazy('/serviceMore/medSend/payComplete/jump'),
        title: '成功',
    },
    {
        path: 'inspectionCheckList',
        element: loadLazy('/serviceMore/InspectionCheck/list/index'),
        title: '检查项',
    },
    {
        path: 'inspectionCheckInfoConfirm',
        element: loadLazy('/serviceMore/InspectionCheck/confirm/index'),
        title: '检查信息确认',
    },
    // ----------------------智能客服-----------------------------
    {
        path: 'intelligenceService',
        element: loadLazy('/serviceMore/intelligenceService'),
        title: '智能客服',
    },
    // ----------------------科室介绍-----------------------------
    {
        path: 'depList',
        element: loadLazy('/serviceMore/depIntroduce/depList'),
        title: '科室介绍',
    },
    {
        path: 'depIntroduce',
        element: loadLazy('/serviceMore/depIntroduce'),
        title: '科室介绍',
    },
    // ----------------------院区介绍-----------------------------
    {
        path: 'hospIntroduce',
        element: loadLazy('/serviceMore/hospIntroduce'),
        title: '院区介绍',
    },
    // ----------------------满意度调查-----------------------------
    {
        path: 'Satisfaction',
        element: loadLazy('/serviceMore/Satisfaction'),
        title: '满意度调查',
    },
    // ----------------------专家排班-----------------------------
    {
        path: 'expertScheduling',
        element: loadLazy('/serviceMore/expertScheduling'),
        title: '排班详情',
    },
    // ----------------------院外导航-----------------------------
    {
        path: 'hospitalNavigate',
        element: loadLazy('/serviceMore/hospitalNavigate'),
        title: '院外导航',
    },
    // ----------------------投诉与反馈-----------------------------
    {
        path: 'complaintFeedback',
        element: loadLazy('/serviceMore/complaintFeedback'),
        title: '投诉与反馈',
    },
    {
        path: 'complaintFeedbackRecord',
        element: loadLazy('/serviceMore/complaintFeedback/record'),
        title: '投诉记录',
    },

    // ----------------------招聘信息-----------------------------
    { path: 'recruitSearch', element: loadLazy('/serviceMore/recruit/search/jump'), title: '招聘信息' },
    {
        path: 'recruitSearchResult',
        element: loadLazy('/serviceMore/recruit/searchResult/jump'),
        title: '招聘详情',
    },
    { path: 'onlineCV', element: loadLazy('/serviceMore/recruit/onlineCV/jump'), title: '在线简历' },
    {
        path: 'educationHappening',
        element: loadLazy('/serviceMore/recruit/onlineCV/educationHappening/jump'),
        title: '学历情况',
    },
    {
        path: 'personalHobby',
        element: loadLazy('/serviceMore/recruit/onlineCV/personalHobby/jump'),
        title: '个人特长或业余爱好',
    },
    {
        path: 'personalPunishment',
        element: loadLazy('/serviceMore/recruit/onlineCV/personalPunishment/jump'),
        title: '个人奖惩情况',
    },
    {
        path: 'personalTraining',
        element: loadLazy('/serviceMore/recruit/onlineCV/personalTraining/jump'),
        title: '培训或进修经历',
    },
    {
        path: 'personInfo',
        element: loadLazy('/serviceMore/recruit/personInfo/jump'),
        title: '个人信息',
    },
    {
        path: 'personEducation',
        element: loadLazy('/serviceMore/recruit/personInfo/user/education'),
        title: '学历情况',
    },
    {
        path: 'personGaugeCulture',
        element: loadLazy('/serviceMore/recruit/personInfo/user/gaugeCultureJump'),
        title: '规培情况',
    },
    {
        path: 'personStudy',
        // component: loadLazy('/serviceMore/recruit/personInfo/user/jump'),
        element: loadLazy('/serviceMore/recruit/personInfo/user/studyJump'),
        title: '学习简历',
    },
    {
        path: 'personWork',
        element: loadLazy('/serviceMore/recruit/personInfo/user/jump'),
        title: '工作经历',
    },

  /*----------------------------自助开单----------------------------------- */
	{
        path: 'selfBillingHospital',
        element: loadLazy('/serviceMore/selfBilling/hospital/jump'),
        title:'选择院区',
    },  // 选择院区
	{
        path: 'selfBillingIndex',
        element: loadLazy('/serviceMore/selfBilling/list/jump'),
        title:'自助开单',
    },  // 自助开单 list
	{
        path: 'selfBillingorder',
        element: loadLazy('/serviceMore/selfBilling/order'),
        title:'订单详情',
        card: true
    },  // 订单详情
	{
        path: 'selfBillingRecord',
        element: loadLazy('/serviceMore/selfBilling/record'),
        title:'自助开单记录',
        card: true
    },  // 记录列表
	{
        path: 'selfBillingRecordDetails',
        element: loadLazy('/serviceMore/selfBilling/recordDetails'),
        title:'自助开单详情',
        card: true
    },  // 记录详情
	{
        path: 'selfBillingPayConfirm',
        element: loadLazy('/serviceMore/selfBilling/payConfirm/index'),
        title:'支付确认',
        card: true
    },  // 支付确认
	{
        path: 'selfBillingPayRefund/:id',
        element: loadLazy('/serviceMore/selfBilling/refund'),
        title:'申请退款',
        card: true
    },  // 申请退款
	{
        path: 'selfBillingSuccessPay/:orderId',
        element: loadLazy('/serviceMore/selfBilling/successPay'),
        title:'支付成功',
        card: true
    },  // 支付成功
    {
        path: 'checkAppointment',
        element: loadLazy('/serviceMore/checkAppointment/index'),
        title:'检查预约',
        card: true
    },  // 检查预约
]
export default moreRoutes
