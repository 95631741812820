import classes from './loading.module.scss'

const Loading = () => {
    return (
        <div className={classes['box']}>
            <div className={classes['box1']}>
                <div className={classes['rotate-animate']}> </div>
                加载中...
            </div>
        </div>
    )
}
export default Loading
