import { loadLazy } from '@/utils'

const myRoutes = [
    {
        path: 'selectRegisterCardWay',
        element: loadLazy('/my/selectRegisterCardWay'),
        title: '选择添加卡方式',
    },
    {
        path: 'healthCard/list',
        element: loadLazy('/my/healthCard'),
        title: '电子健康卡列表',
    },
    {
        path: 'healthCard/details',
        element: loadLazy('/my/healthCardDetails'),
        title: '就诊卡详情',
    },
    {
        path: 'healthCardRegister',
        element: loadLazy('/my/healthCardRegister'),
        title: '注册电子健康卡',
    },
    {
        path: 'healthCardUnbind',
        element: loadLazy('/my/healthCardUnbind'),
        title: '解绑就诊卡',
    },
    {
        path: 'healthCardUnbindRelation',
        element: loadLazy('/my/healthCardUnbindRelation'),
        title: '解除绑定关系',
    },
    {
        path: 'healthCardRegister/humanReview',
        element: loadLazy('/my/healthCardHumanReview'),
        title: '人工审核',
    },
    {
        path: 'healthCardRegister/faceRecognition',
        element: loadLazy('/my/faceRecognition'),
        title: '人脸识别',
    },
    {
        path: 'healthCardReview',
        element: loadLazy('/my/healthCardReview'),
        title: '就诊卡审核列表',
    },
    {
        path: 'healthCardReview/details',
        element: loadLazy('/my/healthCardReviewDetails'),
        title: '申请详情',
    },

    {
        path: 'myDoctor',
        element: loadLazy('/my/myDoctor'),
        title: '我的医生',
    },
    {
        path: 'myAddress',
        element: loadLazy('/my/myAddress'),
        title: '我的地址',
    },
    {
        path: 'myAddress/edit',
        element: loadLazy('/my/myAddressUpdate'),
        title: '编辑地址',
    },
    {
        path: 'registrationRecord',
        element: loadLazy('/my/registrationRecord'),
        title: '挂号记录',
    },
    {
        path: 'registrationRecord/details',
        element: loadLazy('/my/registrationRecordDetails'),
        title: '挂号记录详情',
    },
    {
        path: 'outpatientPaymentRecord',
        element: loadLazy('/my/outpatientPaymentRecord'),
        title: '门诊缴费记录',
    },
    {
        path: 'outpatientPaymentRecord/details',
        element: loadLazy('/my/outpatientPaymentRecordDetails'),
        title: '门诊缴费记录详情',
    },
    {
        path: 'outpatientPaymentVoucher',
        element: loadLazy('/my/outpatientPaymentVoucher'),
        title: '门诊支付凭证',
    },
    {
        path: 'rechargeRecord',
        element: loadLazy('/my/rechargeRecord'),
        title: '预交金支付凭证',
    },
    {
        path: 'messageRemind',
        element: loadLazy('/my/messageRemind'),
        title: '消息提醒设置',
    },
    {
        path: 'abnoramalOrders',
        element: loadLazy('/my/abnoramalOrders'),
        title: '异常订单复核',
    },
]
export default myRoutes
