import { useEffect, useState, Component } from 'react'
import { useNavigate } from 'react-router-dom'
import { ExclamationCircleFill } from 'antd-mobile-icons'
import './index.scss'
import { get_uncleConfig } from '@/utils/auth'
import uncleicApi from '@/api/uncleic'

/**
 * @content {String | reactNode} 提示内容
 * @hideTips { Boolean } 是否隐藏标题
 */
const QjyxTips = (props) => {
  const config = get_uncleConfig() || {} // 配置
  const [content, setContent] = useState('')
  useEffect(() => {
    if(config[props.showName] == 1) {
      getSystemNotice(config[props.queryName])
    }
  }, [])

  const getSystemNotice = (name) => {
    uncleicApi.getSystemNotice(name).then(({ data }) => {
      setContent(data.content)
    })
  }

  const renderTitle = () => {
    return <div className="title"><ExclamationCircleFill color='#F8D982' fontSize={16} /> <span>{props.title}</span> </div>
  }
  return (
    <div className='QjyxTips'>
      {!props.hideTips && renderTitle()}
      <div className='content' dangerouslySetInnerHTML={{ __html: props.content }}></div>
    </div>
  )
}
QjyxTips.defaultProps = {
  content: '提示内容', // 提示内容
  hideTips: false,
  title:'重要提醒'
}


export default QjyxTips
