import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
// import { Popup ,Toast,SearchBar,Checkbox,Tabs} from 'antd-mobile'
import './index.scss'
import { getPageTips, FormPay } from '@/utils'
import outpatientApi from '@/api/outpatient'
import to from 'await-to-js'
import { getStorage } from '@/utils/storage'
import QjyxTips from '@/components/common/Qjyx-tips'
import PayModel from '@/components/common/Qjyx-payModel'
import medSendApi from '../../../../api/medSend'
import jsSDK from '@/utils/jsSDK'
import registerApi from '@/api/register'
const {  querySystemNotice } = registerApi
const MedicalPaymentOrder = () => {
    const navgiate = useNavigate()
    const state = useLocation().state
    const config = getStorage('physicalMake')
    const useInfo = getStorage('userInfo')
    const card = getStorage('healthCard')
    console.log(config, 'config')
    const [tips, setTips] = useState('') // 页首提示
    const [paySuccessTips, setPaySuccessTips] = useState(null) // 支付成功-页尾提示是否显示
    const [payRadio, setPayRadio] = useState('微信支付')

    useEffect(() => {
        getTips()
    }, [])

    // 查询提示
    const getTips = async () => {
        if (config.payDetailsPageShow == 1) {
            const res = await getPageTips(config.payDetailsPageNoticeId)
            setTips(res)
        }
        if (config.paySuccessShow == 1) {
            const res = await getPageTips(config.paySuccessNoticeId)
            setPaySuccessTips(res)
        }
    }
    /**
     * 获取须知内容
     * @param {*} code
     * @param {*} callback 回调函数
     */
    const getQuery = async (code, callback) => {
        const res = await querySystemNotice(code)
        callback(res.data)
    }
    async function handelPay() {
        const payMap = {
            微信支付: 1,
            银行卡支付: 2,
            支付宝支付: 3,
        }
        const payMap2 = {
            微信支付: 3,
            银行卡支付: 4,
            支付宝支付: 5,
        }
        const params = {
            cardName: card.name,
            cardNo: card.cardNumber,
            openId: useInfo.openid,
            patientId: useInfo.patientId || '',
            uniqueId: useInfo.uniqueId,
            areaFlag: 'Z', // 院区标识(必填项， 忠山院区是Z 康健院区是K)
            list: [state],
            price: state.price,
            payCategory: config.payWay == 1 ? null : payMap[payRadio], // 聚合支付类别（必填项，1 微信 2 银行卡 3 支付宝 ）
            payType: config.payWay == 1 ? 1 : payMap2[payRadio], // 支付方式（必填项） 1微信 2支付宝 3建行聚合支付(微信) 4 建行聚合支付(银行卡) 5建行聚合支付(支付宝)
        }
        const [err, res] = await to(outpatientApi.payOrderHealth(params))
        if (err) return
        // navgiate('/payComplete', { state:{ type:'fail', title:'支付失败！', hideBtn:true } })
        // 微信直接支付
        if (config.payWay !== 2) {
            const otherData = {
                path: `/MedicalPaymentDetail/${res.data?.orderId}`,
                btnText: '查看订单详情',
                buttonQuery: null,
                type: 'success',
                title: '支付成功',
                handerShow: true,
            }
            config.paySuccessShow == 1 &&
                getQuery(config.paySuccessNoticeId, (res) => {
                    otherData.tipContent = res.content
                })
            return jsSDK(res.data, () => {
                navgiate('/payComplete', { state: otherData })
            })
        }
        // 聚合支付
        if (config.payWay == 2) {
            switch (payRadio) {
                case '微信支付':
                    window.location.href = `${res.data.url}`
                    break
                case '支付宝支付':
                    navgiate(`/alipay?htmlStr=${res.data.url}`)
                    break
                case '银行卡支付':
                    FormPay(res.data.url)
            }
        } else {
            window.location.href = `${res.data.url}`
        }

        // // 支付成功后--订单状态：已支付
        // return navgiate('/payComplete', { state:{ path:'/MedicalPaymentDetail', buttonQuery:{id:res.token,price:state.pric, status:'90'}, tipContent: paySuccessTips } })
    }

    return (
        <div className="MedicalPaymentOrder">
            {tips && <QjyxTips content={tips} title={'注意事项'} />}

            <div className="TJ-title">体检项目</div>
            <div className="List">
                {state &&
                    state.details.length > 0 &&
                    state.details.map((item, index) => {
                        return (
                            <div key={index} className="item">
                                <div className="left">
                                    {' '}
                                    <img src={require('@/assets/images/test.png')} alt="" /> {item.packageName}
                                </div>
                                <div className="right">￥{item.price}</div>
                            </div>
                        )
                    })}
            </div>

            {config.payWay == 2 && <PayModel model={[payRadio, setPayRadio]} />}

            <div className="MedicalPaymentOrder-btn" onClick={() => handelPay()}>
                (总金额：￥{state.price})支付
            </div>
        </div>
    )
}

export default MedicalPaymentOrder
