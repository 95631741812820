import { loadLazy } from '@/utils'

const allQueryRoutes = [
    {
        path: 'allQueryIndex',
        // element: loadLazy('/allQuery/index'),
        element: loadLazy('/allQuery/newIndex'),
        title: '综合查询',
    },
    {
        path: 'surgeryQuery',
        element: loadLazy('/allQuery/surgery/index'),
        title: '手术进度查询',
    },
    {
        path: 'surgeryDetail',
        element: loadLazy('/allQuery/surgery/detail'),
        title: '手术进度查询',
    },
    {
        path: 'MedicalRecordsQuery',
        element: loadLazy('/allQuery/MedicalRecords/index'),
        title: '门诊病历查询',
    },
    {
        path: 'MedicalRecordsDetail',
        element: loadLazy('/allQuery/MedicalRecords/detail'),
        title: '门诊病历详情',
    },
    {
        path: 'leaveHospitalQuery',
        element: loadLazy('/allQuery/leaveHospital/index'),
        title: '出院带药查询',
    },
    {
        path: 'leaveHospitalDetail',
        element: loadLazy('/allQuery/leaveHospital/detail'),
        title: '出院带药详情',
    },
    {
        path: 'InHospitalDoctorAdviceQuery',
        element: loadLazy('/allQuery/InHospitalDoctorAdvice/index'),
        title: '住院医嘱查询',
    },
    {
        path: 'InHospitalDoctorAdviceDetail',
        element: loadLazy('/allQuery/InHospitalDoctorAdvice/detail'),
        title: '住院医嘱详情',
    },
    {
        path: 'OutHospitalDoctorAdviceQuery',
        element: loadLazy('/allQuery/OutHospitalDoctorAdvice/index'),
        title: '门诊医嘱查询',
    },
    {
        path: 'useMedicine',
        element: loadLazy('/allQuery/useMedicine/index'),
        title: '用药指导',
    },
    {
        path: 'prescriptionQuery',
        element: loadLazy('/allQuery/prescriptionQuery/index'),
        title: '处方查询',
    },
    {
        path: 'prescriptionDetail',
        element: loadLazy('/allQuery/prescriptionQuery/detail'),
        title: '处方查询详情',
    },
    {
        path: 'deptSiteQuery',
        element: loadLazy('/allQuery/deptSite/index'),
        title: '科室位置查询',
    },
    {
        path: 'bedInformation',
        element: loadLazy('/allQuery/bedInformation/index'),
        title: '床位信息查询',
    },
]

export default allQueryRoutes
