import { useEffect } from 'react'
import Routers from './router/index'
import classes from './App.module.scss'
import VConsole from 'vconsole'
import 'antd-mobile-v2/dist/antd-mobile.css'
import { getStorage, clearStorage, setStorage } from '@/utils/storage'
let userInfo = getStorage('userInfo') ? getStorage('userInfo') : {}
function App() {
    // 指定人员开发console
    const UniqueId = ['qjyx825432008427556864', 'qjyx825418188871159808', 'qjyx793528333149130752']
    useEffect(() => {
        const uniqueIdFlag = UniqueId.some((item) => item === userInfo.uniqueId)
        if (process.env?.NODE_ENV == 'development' || uniqueIdFlag) {
            const vConsole = new window.VConsole()
        }
        document.title = '南充高坪区人民医院'
    }, [])

    return (
        <div className={classes.App}>
            <Routers />
        </div>
    )
}

export default App
