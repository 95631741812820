import axios from '@/utils/http' // 导入http中创建的axios实例

const uncleicApi = {
  getNucleicConfig() { // 核酸配置
    return axios.get(`/pay/nucleic/getConfig`, { reqType: 'local', noLoading:true, noParam: true } )
  },
  getSystemNotice(code='') { //就诊须知
    return axios.get(`/pay/nucleic/queryNotice`, { data: code }, )
  },
  getStaff() { //待检测人员类型
    return axios.get(`/pay/nucleic/getStaff`, { reqType: 'local',noParam: true  })
  },
  confirmNucleicItem(deptId) { //确认核酸项目
    return axios.get(`/pay/nucleic/confirmItem`, { data: deptId })
  },
  nucleicApply(data) { //核酸申请次数校验
    return axios({
      url: '/pay/nucleic/applyCheck',
      method: 'post',
      data
    })
  },

  getQrCodeImage(htmlStr='') { //聚合支付（支付宝）返回二维码
    const params = { code: htmlStr}
    return axios.post(`/pay/api/getQrCodeImage`, params)
  },

  // --------------------------------院区选择------------------------------------------------------------
  getArea() {
    return axios.get(`/pay/nucleic/getArea`, { reqType: 'local', noParam: true })
  },

  // --------------------------------核酸项目------------------------------------------------------------
  getItem(areaCode) { //核酸项目
    return axios.get(`/pay/nucleic/getItem`, { data: areaCode })
  },
  listRecord({ areaCode='', cardNo='', openid='' }) { //申请记录
    const params = {areaCode, cardNo, openid}
    return axios.get(`/pay/nucleic/listRecord`,{data: params, reqType: 'local'})
  },

  // --------------------------------核酸排班------------------------------------------------------------
  queryNucleicSchedule(params) { //查询排班
    return axios.get(`/pay/nucleic/querySchedule`, {data: params, reqType: 'local'})
  },
  queryNucleicScheduleDate() { //查询核酸排班显示日期
    return axios.get(`/pay/nucleic/queryScheduleDate`,{ noParam: true })
  },

  // --------------------------------订单管理------------------------------------------------------------
  wechatNucleicOrder(params) { //核酸下单
    return axios.post(`/pay/nucleic/createOrder`, params)
  },
  gatherWeChatPublicPay(params) { //聚合支付
    return axios.post(`/pay/nucleic/placeOrder`, params)
  },
  ccbPlaceOrderHTML(params) { //银行卡支付
    return axios.post(`/pay/nucleic/ccbPlaceOrderHTML`, params)
  },
  wechatPlaceOrder(params) { //微信公众平台支付
    return axios.post(`/pay/nucleic/wechatPlaceOrder`, params)
  },
  getRecord(params) { //查询订单详情
    return axios.get(`/pay/nucleic/getRecord`, {data: params, reqType: 'local'})
  },
  
  
  // --------------------------------问诊表------------------------------------------------------------
  getForm() { //查询问诊表
    return axios.get(`register/other/come/getForm`,{}, { noParam: true })
  },
  registration(params) { //新冠肺炎问诊表登记
    return axios.post(`register/other/come/registration`, params)
  },
  submitQuestion(params) { //新冠肺炎问诊表提交
    return axios.post(`register/api/submitQuestion`, params)
  },
  
}

export default uncleicApi