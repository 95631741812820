/**
 * 首页
 */

import axios from '../utils/http'

const homeApi = {
    // 埋点统计
    functionCount(data) {
        return axios.post(`/query/operate/overview/functionCount`, data)
    },

    /* ----------------------------------------------------首页-------------------------------------------------------- */
    // 微信公众号授权
    wechatAuthorize(data) {
        return axios.post(`/user/wechat/wechatAuthorize?code=${data}`)
    },
    // 微信公众号用户详情
    getUserInfo(data) {
        return axios.get(`/user/wechat/getUserInfo`, data)
    },
    // 立即签到院区信息获取
    searchOutpatientSelfServiceDataByZoneId(data) {
        return axios.get(`/user/operate/qrcode/signin/searchOutpatientSelfServiceDataByZoneId`, data)
    },
    // 立即签到结果查询
    outpatientSelfServiceSignIn(data) {
        return axios.post(`/user/operate/qrcode/signin/outpatientSelfServiceSignIn`, data)
    },
    // 获取token
    login(data) {
        return axios.post(`/backend/system/auth/login`, data, { is_login: true })
    },
    // 查询-首页列表
    getExamineList(data) {
        return axios.get(`/query/index/getExamineList`, data)
    },
    // 查询-功能列表
    getFunctionList(data) {
        return axios.get(`/query/index/getFunctionList`, data)
    },
    // 查询-默认电子健康卡
    getDefaultCard(uniqueId) {
        return axios.get(`/query/index/getDefaultCard`, { data: uniqueId })
    },

    // 医生搜索
    getDoctorByDeptOrNameOrDisease(data) {
        return axios.get(`/query/index/getDoctorByDeptOrNameOrDisease`, data)
    },
    // 更多服务
    getMoreService(data) {
        return axios.get(`/query/index/getFunctionListByName`, data)
    },
    // 关联菜单
    queryLinkFunctions(data) {
        return axios.get(`/query/index/queryLinkFunctions`, data)
    },
    // 关联菜单配置获取
    queryPersonalizeFunction(data) {
        return axios.get(`/query/index/queryPersonalizeFunction`, data)
    },
    authOpenId(data) {
        return axios.get(`/query/wechat/auth`, { data })
    },
}

export default homeApi
