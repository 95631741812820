import { loadLazy } from '@/utils'

const homeRoutes = [
    {
        path: 'messageList',
        element: loadLazy('/home/messageList'),
        title: '消息列表',
    },
    {
        path: 'search',
        element: loadLazy('/home/search'),
        title: '搜索',
    },
    {
        path: 'upgrade',
        element: loadLazy('/home/upgrade'),
        title: '维护升级',
    },
    {
        path: 'associatedMenu',
        element: loadLazy('/home/associatedMenu'),
        title: '关联菜单',
    },
]

export default homeRoutes
