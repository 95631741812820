import { loadLazy } from '@/utils'
import MedicalPaymentOrder from '../../views/serviceOutpatient/medicalPayment/order'

// 门诊服务路由
const outpatientRoters = [
  // --------------------停车预约--------------------------------
  {
    path: 'parkingReservation/index',
    element: loadLazy('/serviceOutpatient/parkingReservation/appointment'),
    title: '停车预约',
  },
  {
    path: 'parkingReservation/record',
    element: loadLazy('/serviceOutpatient/parkingReservation/record'),
    title: '停车预约记录',
  },
  {
    path: 'parkingReservation/details',
    element: loadLazy('/serviceOutpatient/parkingReservation/details'),
    title: '详情',
  },
  {
    path: 'parkingReservation/edit',
    element: loadLazy('/serviceOutpatient/parkingReservation/edit'),
    title: '修改',
  },
  {
    path: 'parkingReservation/resultPage',
    element: loadLazy('/serviceOutpatient/parkingReservation/resultPage'),
    title: '预约完成',
  },

  // --------------------核酸预约--------------------------------
  {
    path: 'uncleicMake/notice',
    element: loadLazy('/serviceOutpatient/uncleicMake/notice'),
    title: '患者告知书',

  },
  {
    path: 'uncleicMake/choiceHosp',
    element: loadLazy('/serviceOutpatient/uncleicMake/choiceHosp'),
    title: '选择院区',
  },
  {
    path: 'uncleicMake/unclePro',
    element: loadLazy('/serviceOutpatient/uncleicMake/unclePro'),
    title: '核酸检测项目',
  },
  {
    path: 'uncleicMake/uncleClass',
    element: loadLazy('/serviceOutpatient/uncleicMake/uncleClass'),
    title: '核酸检测排班',
  },
  {
    path: 'uncleicMake/orderDetails/:pageStatus/:orderId',
    element: loadLazy('/serviceOutpatient/uncleicMake/orderDetails'),
    title: '核酸订单详情',
  },
  {
    path: 'uncleicMake/recordDetails/:pageStatus/:orderId',
    element: loadLazy('/serviceOutpatient/uncleicMake/recordDetails'),
    title: '订单详情', // 支付回调页面展示
  },
  // --------------------门诊缴费--------------------------------
  {
    path: 'outpatientList',
    element: loadLazy('/serviceOutpatient/outpatientPayment/outpatientList'),
    title: '门诊缴费列表',
  },
  {
    path: 'outpatientPay',
    element: loadLazy('/serviceOutpatient/outpatientPayment/outpatientPay'),
    title: '门诊缴费',
  },
  // --------------------预约挂号--------------------------------
  {
    path: 'bookingRegister',
    element: loadLazy('/serviceOutpatient/bookingRegister'),
    title: '预约挂号',
  },
  {
    path: 'doctorScheduling',
    element: loadLazy('/serviceOutpatient/doctorScheduling'),
    title: '医生排班',
  },
  {
    path: 'doctorIntro',
    element: loadLazy('/serviceOutpatient/doctorIntro'),
    title: '医生介绍',
  },
  {
    path: 'bookingRegisterOrder',
    element: loadLazy('/serviceOutpatient/bookingRegisterOrder'),
    title: '订单信息',
  },
  // --------------------体检缴费--------------------------------
  {
    path: 'medicalPayment',
    element: loadLazy('/serviceOutpatient/medicalPayment/index'),
    title: '体检缴费',
  },
  {
    path: 'MedicalPaymentOrder',
    element: loadLazy('/serviceOutpatient/medicalPayment/order/index'),
    title: '体检缴费订单',
  },
  {
    path: 'MedicalPaymentDetail/:orderId',
    element: loadLazy('/serviceOutpatient/medicalPayment/detail/index'),
    title: '体检缴费订单详情',
  },
  // --------------------智能导诊--------------------------------
  {
    path: 'intelligentGuidance',
    element: loadLazy('/serviceOutpatient/intelligentGuidance/choose/index'),
    title: '智能导诊',
  },
  {
    path: 'guidanceResult',
    element: loadLazy('/serviceOutpatient/intelligentGuidance/result/index'),
    title: '智能导诊',
  },
  // --------------------预加号缴费--------------------------------
  {
    path: '/numberPlusPay',
    element: loadLazy('/serviceOutpatient/numberPlusPay'),
    title: '预加号缴费',
  },
  // --------------------排队等候--------------------------------
  {
    path: '/waitList/:title',
    element: loadLazy('/serviceOutpatient/awaitLine/waitList'),
    title: '排队列表',
  },
  {
    path: '/waitDetails/:title',
    element: loadLazy('/serviceOutpatient/awaitLine/waitDetails'),
    title: '排队详情',
  },
  // --------------------报告查询--------------------------------
  {
    path: '/reportRecord',
    element: loadLazy('/serviceOutpatient/report/reportRecord'),
    title: '报告查询',
  },
  {
    path: '/reportList',
    element: loadLazy('/serviceOutpatient/report/reportList'),
    title: '报告查询列表',
  },
  {
    path: '/reportDetails',
    element: loadLazy('/serviceOutpatient/report/reportDetails'),
    title: '报告查询详情',
  },
  {
    path: '/examiDetails', // 体检报告详情
    element: loadLazy('/serviceOutpatient/report/examiDetails'),
    title: '报告查询详情',
  },
  // --------------------新冠预防中药--------------------------------
  {
    path: '/convenienceDrug',
    element: loadLazy('/serviceOutpatient/convenienceDrug'),
    title: '新冠预防中药',
  },
  {
    path: '/convenienceDrug/order',
    element: loadLazy('/serviceOutpatient/convenienceDrug/order'),
    title: '订单信息',
  },
  {
    path: '/convenienceDrug/record',
    element: loadLazy('/serviceOutpatient/convenienceDrug/record'),
    title: '申请记录',
  },
  {
    path: '/convenienceDrug/recordDetails/:orderId',
    element: loadLazy('/serviceOutpatient/convenienceDrug/recordDetails'),
    title: '申请详情',
  },
  // --------------------自助缴费--------------------------------
  {
    path: '/selfCheckIn',
    element: loadLazy('/serviceOutpatient/selfCheckIn/index'),
    title: '自助签到',
  },
  {
    path: '/selfCheckInResult',
    element: loadLazy('/serviceOutpatient/selfCheckInResult/index'),
    title: '自助签到结果',
  },
  // --------------------体检中心--------------------------------
  {
    path: '/physicalCenter',
    element: loadLazy('/serviceOutpatient/physicalCenter/index'),
    title: '体检中心',
  },
  {
    path: '/physicalCenter/report',
    element: loadLazy('/serviceOutpatient/physicalCenter/reports/index'),
    title: '体检报告',
  },
  {
    path: '/physicalCenter/card',
    element: loadLazy('/serviceOutpatient/physicalCenter/card/index'),
    title: '体检人',
  },
  {
    path: '/physicalCenter/query',
    element: loadLazy('/serviceOutpatient/physicalCenter/card/query/index'),
    title: '添加体检人-查询',
  },
  {
    path: '/physicalCenter/addEdit',
    element: loadLazy('/serviceOutpatient/physicalCenter/card/addEdit/index'),
    title: '添加体检人',
  },
  {
    path: '/physicalCenter/assessment',
    element: loadLazy('/serviceOutpatient/physicalCenter/assessment/index'),
    title: '健康评估',
  },
  {
    path: '/physicalCenter/assessmentRisk',
    element: loadLazy('/serviceOutpatient/physicalCenter/assessment/assessmentRisk/index'),
    title: '健康风险评估',
  },
  {
    path: '/physicalCenter/questionnaire',
    element: loadLazy('/serviceOutpatient/physicalCenter/assessment/questionnaire/index'),
    title: '问卷详情',
  },
  {
    path: '/physicalCenter/packages',
    element: loadLazy('/serviceOutpatient/physicalCenter/assessment/packages/index'),
    title: '推荐套餐',
  },
]

export default outpatientRoters
