import { loadLazy } from '@/utils'

const inHospitalRouters = [
    {
        path: '/commonSelectList',
        element: loadLazy('/serviceOutpatient/inHospital/commonSelectList'),
        title: '公共选卡页面',
    },
    {
        path: '/HospitalAdvancePaymentIndex',
        element: loadLazy('/serviceOutpatient/inHospital/HospitalAdvancePayment/index'),
        title: '住院预交金',
    },
    {
        path: '/HospitalAdvancePaymentIndexRecord',
        element: loadLazy('/serviceOutpatient/inHospital/PaymentRecords/index'),
        title: '预交金缴费记录',
    },
    {
        path: '/PaymentRecords/details',
        element: loadLazy('/serviceOutpatient/inHospital/PaymentRecords/details'),
        title: '预交金记录详情',
    },
    {
        path: '/balanceQuery',
        element: loadLazy('/serviceOutpatient/inHospital/balanceQuery/index'),
        title: '余额查询',
    },
    {
        path: '/HospitalDayList',
        element: loadLazy('/serviceOutpatient/inHospital/HospitalDaysList/index'),
        title: '住院日清单',
    },
    {
        path: '/HospitalRegister',
        element: loadLazy('/serviceOutpatient/inHospital/HospitalRegister'),
        title: '入院登记',
    },
    {
        path: '/HospitalRegisterDetails',
        element: loadLazy('/serviceOutpatient/inHospital/HospitalRegister/details'),
        title: '入院登记详情',
    },
]
export default inHospitalRouters
