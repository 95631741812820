import Cookies from 'js-cookie'
import { setStorage, getStorage, removeStorage} from '@/utils/storage'

const TokenKey = 'access_token'
const Userid = 'Userid'
const Openid = 'openid'
const uncleConfig = 'uncleConfig' // 核酸配置

// Token
export function getToken() {
  return Cookies.get(TokenKey)
}
export function setToken(token) {
  return Cookies.set(TokenKey, token)
}
export function removeToken() {
  return Cookies.remove(TokenKey)
}

// Userid
export function getUserid() {
  return Cookies.get(Userid)
}
export function setUserid(userid) {
  return Cookies.set(Userid, userid)
}
export function removeUserid() {
  return Cookies.remove(Userid)
}

// openid
export function getOpenId() {
  // return Cookies.get(Openid)
  return getStorage('userInfo').openid
}
// export function setOpenId(openid) {
//   return Cookies.set(Openid, openid)
// }
// export function removeOpenId() {
//   return Cookies.remove(Openid)
// }

// 核酸配置
export function get_uncleConfig() {
  return getStorage(uncleConfig)
}
export function set_uncleConfig(val) {
  return setStorage(uncleConfig, val)
}
export function remove_uncleConfig() {
  return removeStorage(uncleConfig)
}
