import { useEffect, useState, Component } from 'react'
import { Radio, Button } from 'antd-mobile'
import './index.scss'

/**
 * @model {Array[]} ----model[0]: 绑定值  model[1]:修改绑定值 
 * @list { Array } 支付方式--默认三种
 * @title { String } 标题
 * 
 */


const PayModel = (props) => {
  const { model } = props

  useEffect(() => { }, [])

  return (
    <div className='qjyx-paymodel'>
      <h3>{props.title}</h3>
      <div className='qjyx-paymodel-content'>
        <Radio.Group
          value={model[0]}
          onChange={val => {
            console.log(val);
            model[1](val)
          }}
        >
          {
            props.list.map((item, index) => {
              return (
                <div key={index} className='paymodelItem'>
                  <div className='paymodelItem-l'>
                    <img src={item.icon} alt="" />
                    <span>{item.text}</span>
                  </div>
                  <Radio style={{ '--icon-size': '15px' }} value={item.text}></Radio>
                </div>
              )
            })
          }
        </Radio.Group>
      </div>
    </div>
  )
}
PayModel.defaultProps = {
  title:'请选择支付方式',
  list: [
    {
      icon: require('../../../assets/images/wechat.png'),
      text: '微信支付',
      id: '1'
    },
    {
      icon: require('../../../assets/images/applay.png'),
      text: '支付宝支付',
      id: '2'
    },
    {
      icon: require('../../../assets/images/blank.png'),
      text: '银行卡支付',
      id: '3'
    },
  ]
}

export default PayModel
