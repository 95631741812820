import React from 'react'
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'
import { loadLazy, getQuery } from '@/utils'
import myRoutes from './modules/my'
import homeRoutes from './modules/home'
import outpatientRoters from './modules/outpatient'
import moreRoutes from './modules/more'
import allQueryRoutes from './modules/allQuery'
import inHospitalRouters from './modules/inHospital'
import { getStorage, setStorage } from '@/utils/storage'

let routerArray = [
  {
    path: 'loading',
    element: loadLazy('/public/loading'),
    title: '加载中...',
  },
  {
    path: 'home',
    element: loadLazy('/home'),
    title: '首页',
  },
  {
    path: '/',
    element: <Navigate to="/home" />,
    // element: (
    //   <React.Suspense fallback={<>加载中...</>}>
    //     <Navigate to="/home" />
    //   </React.Suspense>
    // ),
    title: '首页',
  },
  {
    path: 'myCenter',
    element: loadLazy('/my'),
    title: '个人中心',
  },

  {
    path: 'serviceMore/:title',
    element: loadLazy('/serviceMore'),
    title: '更多服务',
  },
  {
    path: 'result',
    element: loadLazy('/public/resultPage/index'),
    title: '结果',
  },
  {
    path: 'payAwait',
    element: loadLazy('/public/payAwait'),
    title: '交易处理中',
  },
  {
    path: 'payComplete',
    element: loadLazy('/public/payComplete'),
    title: '支付成功',
  },
  {
    path: 'consform',
    element: loadLazy('/public/consform'),
    title: '新冠肺炎流行病史问诊表',
  },
  {
    path: 'consformResult/:status',
    element: loadLazy('/public/consformResult'),
    title: '新冠肺炎流行病学史问诊结果',
  },
  {
    path: 'alipay',
    element: loadLazy('/public/alipay'),
    title: '支付宝支付',
  },
  ...myRoutes,
  ...homeRoutes,
  ...outpatientRoters,
  ...moreRoutes,
  ...allQueryRoutes,
  ...inHospitalRouters,
]

// 白名单(不需要token)
const whitePath =['loading', 'payAwait', 'payComplete' ]

// 获取路由组件
const GetComp = ({ item }) => {
  document.title = item.title
  if (item.path === '/') return item.element
  if (!getStorage('accessToken') && !whitePath.includes(item.path)) {
    let query = getQuery()
    return <Navigate to={`/loading${query}`} />
  }

  return (
    <React.Suspense>
      <item.element />
    </React.Suspense>
  )
}
// 获取路由
const getRoutes = (paramsRouter) => {
  return paramsRouter.map((routerItem) => {
    return (
      <Route key={routerItem.path} path={routerItem.path} element={<GetComp item={routerItem} />}>
        {routerItem.children?.length > 0 && getRoutes(routerItem.children)}
      </Route>
    )
  })
}

// 打包需加上/nwx 本地项目启动需去掉nwx
const Routers = () => {
  return <BrowserRouter basename='/nwx'>
    <Routes>{getRoutes(routerArray)}</Routes>
  </BrowserRouter>
}
export default Routers
