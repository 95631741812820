/**
 * 电子居民健康卡
 */

import axios from '../utils/http'

const medSendApi = {
  /* ----------------------------------------------------病历邮寄Api-------------------------------------------------------- */

  /**
   * 查询配置
   */
  getConfig(data) {
    return axios.get(`/register/mail/getConfig`, {data})
  },
  /**
   * 保存身份验证临时表
   */
   saveApplyIdentity(data) {
    return axios.post(`/register/mail/saveApplyIdentity`, data)
  },
  /**
   * 查询身份验证临时表
   */
   getApplyIdentity(uniqueId) {
    return axios.get(`/register/mail/getApplyIdentity`, {data:uniqueId})
  },
    /**
   * 获取须知配置
   * @params {Object} data
   */
    getAgree(data) {
      return axios.get(`/register/mail/queryNotice`, { data })
    },

  /**
   * 查询病案首页临时表
   */
   getApplyInfo(uniqueId) {
    return axios.get(`/register/mail/getApplyInfo`, {data:uniqueId})
  },

  /**
   * 保存病案首页临时表
   */
   saveApplyInfo(data) {
    return axios.post(`/register/mail/saveApplyInfo`, data, )
  },
  /**
   * 根据身份证查询住院记录
   */
   getPatientInHospital(IdCard) {
    return axios.get(`/register/mail/getPatientInHospital`, {data:IdCard})
  },

  /**
   * 保存病例寄送记录
   */
   saveApply(data) {
    return axios.post(`/register/mail/saveRecordApply`, data)
  },

  /**
   * 查询病例寄送记录列表
   */
   getMedicalRecordList({type,uniqueId }) {
    return axios.get(`/register/mail/listRecord`, {data:{type:type,uniqueId:uniqueId}})
  },
  /**
   * 病案记录详细数据
   */
    getApplyIdByApply(id) {
        return axios.get(`/register/mail/getApplyIdByApply`, {data:id})
    },
  /**
   * 微信下单
   */
   wechatPlaceOrder(data) {
    return axios.post(`/register/mail/wechatPayOrder`, data, )
  },
    /**
     * 微信下单 new
     */
    placeOrder(data) {
        return axios.post(`/register/mail/placeOrder`, data, )
    },
  /**
   * 取消订单
   */
   closeOrder(id) {
    return axios.post(`/register/mail/close`, id)
  },
  /**
   * 根据病案记录id查询支付数据
   * 订单数据（订单号用于展示）
   */
   getApplyIdByOrder(id) {
    return axios.get(`/register/mail/getApplyIdByOrder`, {data:id})
  },
    /**
     * 根据病案记录id查询订单详细信息
     *
     */
    getRecord(id) {
        return axios.get(`/register/mail/getRecord`, {data:id})
    },
  /**
   * 更新记录列表
   */
   updateApply(id) {
    return axios.post(`/register/mail/updateApply`, id, )
  },
  /**
   * 支付确认页面获取已付费
   */
   getPayment(id) {
    return axios.get(`/register/mail/getPayment`, {data:id}, )
  },


}

export default medSendApi
